.Réserver_Solutions {
  padding: 30px 26px;
  border-radius: 40px;
  background-color: #f9fafb;
  width: 100%;
}

.Réserver_Solutions .container {
  margin-top: 20px;
  max-width: 500px;
}

.Réserver_Solutions .container h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
}

.Réserver_Solutions .container p {
  font-size: 17.47px;
  font-weight: 400;
  line-height: 26.2px;
  margin-top: 20px;
}

.Réserver_Solutions .container h5 {
  margin-top: 14px;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
}

.Réserver_Solutions .line {
  border-bottom: solid 1px #e3e3e3;
  width: 100%;
}

.Réserver_Solutions .item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.Réserver_Solutions .item-container .right {
  width: 500px;
  position: sticky;
  bottom: 0;
  height: fit-content;
  /* margin-top: auto; */
  margin-left: 0;
  margin-bottom: 0;
  max-width: 100%;
  padding: 20px;
}

.Réserver_Solutions .right > button {
  max-width: 100%;
}

.Réserver_Solutions .add.white {
  background-color: white;
  color: #000000;
}

.Réserver_Solutions .item-container .right h4 {
  font-size: 18px;
  margin-bottom: 25px;
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 7px;
}

.Réserver_Solutions .item-container .right h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.Réserver_Solutions .item-container .right .paragraph {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-top: 10px;
}

.Réserver_Solutions .item-container .right .paragraph li {
  margin-top: 10px;
  margin-left: 20px;
  list-style: outside;
}
.Réserver_Solutions .flex-upload {
  /* flex-direction: column; */
  position: relative;
  gap: 20px;
  flex-wrap: wrap;
}

.Réserver_Solutions .flex-upload button {
  background-color: red;
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 16px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Réserver_Solutions .flex-upload {
  width: 100%;
}
.Réserver_Solutions .flex-upload img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 20px;
}
.Réserver_Solutions .flex-upload .upload-button {
  width: 150px;
  height: 150px;
  border: dashed 1px #377dff;
  border-radius: 20px;
}
.Réserver_Solutions .flex-upload .upload-button img {
}

@media (max-width: 560px) {
  .app-body:has(.Réserver_Solutions) {
    padding: 0;
  }
  .Réserver_Solutions {
    padding: 0;
    border-radius: 10px;
    padding: 0px;
    padding-top: 0px;
    border-radius: 20px;
  }
  .Réserver_Solutions .container {
    padding: 20px;
    padding-top: 0;
  }
  .Réserver_Solutions .tabs-container {
    background-color: transparent;
    margin-bottom: 0;
    width: 100%;
    margin: auto;
    overflow: visible;
  }
  .Réserver_Solutions .item-container {
    justify-content: center;
  }
  .Réserver_Solutions .item-container .right {
    margin: 0;
  }
}
