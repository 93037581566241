.Documents {
    padding: 12px 20px;
    border-radius: 20px;
    background: #F9FAFB;
}

.app-body:has(.Documents) {
    position: relative;
}

.Documents button.add {
    width: 175px;
    max-width: initial;
    font-size: 14px;
    height: 44px;
}

.Documents .folders {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.Documents .folder {
    padding: 20px 11px;
    box-shadow: 0px 8.45px 23.93px 0px #0D0A2C14;
    box-shadow: 0px 23.93px 18.3px 0px #0D0A2C0D;
    width: 260px;
    border-radius: 14.08px;
    background-color: white;
    max-width: 260px;
    height: 170px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.Documents .folder .avatar img {
    border-radius: 0;
    width: 40px;
    height: 30px;
}

.Documents .folder .avatar {
    gap: 14px;
    height: fit-content;
    flex: none;
}

.Documents .folder .avatar button {
    margin-left: auto;
}

.Documents .users {
    margin-top: auto;
    margin-left: auto;
    margin-right: 20px;
}

.Documents .user .items {
    background-color: #377DFF;
}

.Documents>.flex {
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 24px;
}

.Documents>.flex a {
    color: #377DFF;
    text-decoration: underline;
}

@media (max-width:560px) {
    .Documents .folder {
        max-width: 100%;
        width: 100%;
    }
}