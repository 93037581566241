.Users h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    gap: 20px;
    white-space: nowrap;
}

.Users h3 a {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

button.add {
    width: 100%;
    max-width: 125px;
    min-width: 125px;
    height: 44px;
    border-radius: 8px;
    border: 0.8px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.58px;
    gap: 10px;
    background-color: #377DFF;
    border: 0.8px solid #DBDFED;
    color: white;
}

button.add svg {
    font-size: 18px;
}