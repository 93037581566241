.Objects-container {
  position: absolute;
  left: 0;
  top: 0;
  padding: 30px;
  max-width: 100%;
}

.Objects-container.save {
  right: 0;
  bottom: 0;
  left: initial;
  top: initial;
  width: 400px;
}

.Staging .overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #0002;
  display: flex;
}

.Staging .Objects {
  border-radius: 8px;
  width: 366px;
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.Staging .top {
  background-color: #ffffffcc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.85px;
  text-align: left;

  height: 40px;
  padding: 10px 15px 10px 15px;
  border-radius: 8px;
  color: #747474;
  cursor: pointer;
}

.Objects-container.save button {
  width: 100%;
  justify-content: center;
}

.Objects .top p {
  max-width: calc(100% - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Objects .top button {
  transform: rotate(180deg);
}
.Objects.active .top button {
  transform: rotate(0deg);
}
.Objects .body {
  padding: 20px 17px;
  background-color: #ffffffcc;

  margin-top: 3px;
  border-radius: 8px;
}

.Objects .body .nav {
  display: flex;
  gap: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.85px;
  text-align: left;
  align-items: center;
  gap: 10px;
}
.Objects .body .nav button {
  background-color: #377dff;
  color: #e6e6e6;
  height: 34px;
  flex: 1;
  border-radius: 8px;
}

.Objects .body .nav li {
  height: 20px;
  display: flex;
  align-items: center;
  border-bottom: solid 0.5px #000;
  cursor: pointer;
}
.Objects .line {
  border-bottom: 0.5px solid #e0e0e0;
  margin: 10px 0 0;
}
.Objects .input-container {
  position: relative;
  border: 0.5px solid #e6e6e6;

  background-color: #ffffff00;
  padding: 0;
  border-radius: 8px;
  margin-top: 10px;
  /* overflow: hidden; */
}
.Objects .input-container label {
  position: absolute;
  left: 15px;
  top: 0;
  transform: translateY(-50%);
  background-color: #ffffff00;

  padding: 0 5px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14.02px;
  text-align: left;
}

.Objects .input-container svg {
  position: absolute;
  right: 15px;
  z-index: 99;
  color: #747474;
  top: 50%;
  transform: translateY(-50%);
}

.Objects .input-container input,
.Objects .input-container select {
  border: none;
  width: 100%;
  background-color: #ffffffaa;
  padding: 8.93px 19px 8.07px 19px;
  gap: 0px;
  border-radius: 8px;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.85px;
  text-align: left;
}
.Objects .input-container select {
  cursor: pointer;
}

.Objects .input-container select::marker {
  opacity: 0;
}
.Objects .select-item {
  display: flex;
  max-width: 100%;
  overflow: auto;
  margin-top: 10px;
}
.Objects .select-item::-webkit-scrollbar {
  display: none;
}
.Objects .select-item button {
  padding: 10px 14px;
  font-size: 13.07px;
  font-weight: 400;
  line-height: 18.67px;
  text-align: left;
  color: #656565;
  border-radius: 20px;
}
.Objects .select-item button.active {
  background-color: #000000;
  color: white;
}
.Objects .items-3d {
  border: 0.5px solid #ededed;
  border-radius: 26px;
  padding: 16px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  max-height: 200px;
  overflow: auto;
}
.Objects .items-3d::-webkit-scrollbar {
  display: none;
}

.Objects .item-3d {
  min-width: calc(30% - 9px);
  flex: 1;
  padding-bottom: 30%;
  background-color: #cecece;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.Objects .item-3d img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Staging-options-container {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 10px;
  border-radius: 20px;
  width: 100%;

  max-width: 425px;
}
.Staging-options {
  width: 150px;
  background-color: #ffffffcc;

  padding: 10px 8px 10px 8px;
  border-radius: 10px;
  border: 10px 0px 0px 0px;
}

.Staging-options button {
  height: 40px;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 12px;
  border-bottom: solid 1px #e2e8f0;
  width: 100%;
}
.Staging-options button:last-child {
  border-bottom: none;
}

@media (max-width: 1200px) {
  .Staging-options-container {
    right: initial;
    left: 0;
    top: 50px;
    padding: 30px;
    z-index: 99;
  }
}
@media (max-width: 720px) {
  .Objects-container {
    left: 0;
    top: 0;
    padding: 16px;
  }
  .Staging-options-container {
    right: initial;
    left: 0;
    top: 50px;
    padding: 15px;
    width: 395px;
    max-width: 100%;
  }
}
