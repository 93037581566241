.HistoriquePaiements {
  background-color: white;
  flex: 3;
  min-height: 100%;
  border-radius: 20px;
  padding: 20px 15px 10px;
  display: flex;
  flex-direction: column;
  min-width: min(400px, 100%);
  gap: 24px;
  justify-content: space-between;
}

.HistoriquePaiements h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 37.8px;
  text-align: left;
  color: #4f4f4f;
  display: flex;
  gap: 36px;
  align-items: center;
}

.HistoriquePaiements h2 span {
  padding: 5px 15px;
  background-color: #ecf5fe;
  color: #1cc363;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
}

.HistoriquePaiements .months {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 20px;
  max-width: 600px;
}

.HistoriquePaiements .months > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12.58px;
  font-weight: 400;
  width: 80px;
  line-height: 15.85px;
  text-align: left;
  gap: 10px;
}

.HistoriquePaiements .months > div svg {
  font-size: 26px;
  color: #bdbdbd;
}

.HistoriquePaiements .months > div.active svg {
  color: #377dff;
}
.HistoriquePaiements .months > div.warning svg {
  color: #fa0;
}

.HistoriquePaiements .nbmois {
  border: 1px solid #e1e1e1;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.HistoriquePaiements .nbmois p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #525252;
  text-align: left;
}

.HistoriquePaiements .nbmois h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #377dff;
  margin-bottom: 5px;
}

.HistoriquePaiements .month-count {
  height: 70px;
  width: 70px;
  min-width: 70px;
  position: relative;
}

.HistoriquePaiements .month-count .c {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.HistoriquePaiements .month-count .c p {
  text-align: center;
}

.HistoriquePaiements .month-count span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #262626;
  white-space: nowrap;
}

.HistoriquePaiements .month-count svg {
  transform: rotate(-90deg);
}
