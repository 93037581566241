.AddObjectForm {
}

.AddObjectForm .upload-container {
  white-space: nowrap;
  display: flex;
}

.AddObjectForm .upload-container > .flex {
  row-gap: 0;
}

.AddObjectForm .upload-container label {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: flex;
}

.AddObjectForm .img-src {
  position: relative;
}

.AddObjectForm .img-src img {
  height: 160px;
  width: 100%;
  object-fit: contain;
  border: solid 1px #eee;
  border-radius: 5px;
}

.AddObjectForm .img-src button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #0005;
  height: 25px;
  width: 25px;
  border-radius: 50%;

  color: white;
}

.AddObjectForm .img-src button svg {
  margin: auto;
}

.AddObjectForm .upload-container p {
  overflow: hidden;
  margin-top: 0;
  text-overflow: ellipsis;
  max-width: 250px;
  margin-bottom: 20px;
}
