.LoadingAnime {
    /* display: none; */
    pointer-events: none;
    overflow: hidden;
}

.LoadingAnime .app-contents {
    overflow: hidden;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0
    }
    100% {
        background-position: 800px 0
    }
}

.LoadingAnime .SideBar {
    border: none;
}

.LoadingAnime .SideBar .img {
    width: 100%;
    height: 60px;
}

.LoadingAnime .SideBar li {
    height: 40px;
    width: 100%;
}

.LoadingAnime .anime {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    border: none !important;
    border-radius: 5px;
}

.LoadingAnime .menu-button.anime {
    height: 40px;
    width: 40px;
}

.LoadingAnime .user.anime {
    height: 54px;
    max-width: 54px;
    border-radius: 50%;
}

.LoadingAnime h2 {
    height: 40px;
    width: 400px;
    max-width: 100%;
}

.LoadingAnime h6 {
    height: 20px;
    width: 300px;
    margin-top: 5px;
    max-width: 100%;
}

.LoadingAnime .icon.anime {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.LoadingAnime .anime.data {
    flex: 1
}

.LoadingAnime h5 {
    height: 16px;
    width: 160px;
}

.LoadingAnime span {
    height: 25px;
    width: 100px;
    display: flex;
}

.LoadingAnime p {
    height: 25px;
    width: 100px;
}

.LoadingAnime .card-2 .img {
    width: 100%;
    height: 200px;
}

.LoadingAnime .item {
    height: 40px;
}

@media (max-width:1100px) {
    .LoadingAnime .chart-container {
        margin-top: 10px;
    }
}