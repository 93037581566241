.react-calendar {
  border: none;
  margin: 10px 0;
  padding: 20px;
  padding-top: 10px;
  border-radius: 12px;
  min-width: 100%;
}

.react-calendar .react-calendar__navigation__arrow {
  /* display: none; */
  font-size: 20px;
  background-color: #f3f2f5;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar .react-calendar__navigation__next2-button,
.react-calendar .react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar .react-calendar__month-view__weekdays__weekday * {
  text-decoration: none;
}

.react-calendar .react-calendar__tile {
  color: #1a191f;
  border-radius: 20px;
}

.react-calendar .react-calendar__tile.react-calendar__tile--now {
  background-color: #f3f2f5;
  color: #1a191f;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar .react-calendar__tile--active {
  background-color: #377dff;
  color: white;
}

.Calendar-planifier .time {
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  padding: 20px 0;
}

.react-calendar .react-calendar__tile:disabled,
.react-calendar .react-calendar__tile.disabled {
  color: #f00a;
  background: transparent;
}

.react-calendar .react-calendar__tile.disabled.react-calendar__tile--active {
  background-color: #f00a;
  color: white;
}

.Calendar-planifier .time button {
  flex: 1;
  min-width: 100px;
  height: 26px;
  background-color: #f3f2f5;
  font-size: 10.64px;
  font-weight: 400;
  line-height: 15.95px;
  text-align: center;
  border-radius: 6px;
}

.Calendar-planifier .time button.active {
  color: white;
  background-color: #377dff;
}
