.Proprietes {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: stretch;
}

.Proprietes-container>a {
    margin-left: auto;
    display: flex;
    width: fit-content;
}

.Proprietes-container>a button {
    width: 140px;
}

.Proprietes .card-2 {
    min-width: 300px;
    max-width: 360px;
}

.Proprietes .card-body .flex .item {
    font-size: 10px;
}

@media (max-width: 560px) {
    .Proprietes {
        /* padding: 20px; */
    }
    .Proprietes .card-2 {
        max-width: 100%;
    }
}