.Call-Navigation {
}

.Call-Navigation .navigation {
  display: flex;
}

.Call-Navigation .navigation a {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  border-radius: 21px;
  font-size: 14px;
  color: #000;
}

.Call-Navigation .navigation a.active {
  background: #000;
  color: #fff;
}

.Call-Navigation .call-card {
  background-color: white;
  padding: 15px 14px;
  border-radius: 34px;
  margin-top: 8px;
  display: flex;
  gap: 14px;
  align-items: stretch;
  min-height: 232px;
  justify-content: space-between;
  width: fit-content;
  flex-wrap: wrap;
}
.Call-Navigation .call-card > * {
  flex: 1;
  min-width: 200px;
}

.Call-Navigation .call-card .type {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  width: 260px;

  border: solid 1px #dadada;
}

.Call-Navigation .call-card .type button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #8e8e8e;
  min-height: 80px;
  position: relative;
}
.Call-Navigation .call-card .type button:has(input:checked) {
  background-color: #dce8ff;
  color: #377dff;
}

.Call-Navigation .call-card .type button:first-child svg {
  font-size: 37px;
  margin-right: 5px;
}
.Call-Navigation .call-card .type input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.Call-Navigation .call-card .type h4 {
  display: flex;
  align-items: center;
  gap: 6.66px;
  font-size: 16px;
}

.Call-Navigation .call-card .type span {
  font-size: 10px;
}

.Call-Navigation .call-card .type h4 svg {
  font-size: 28px;
  height: 37px;
}
.Call-Navigation h5 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 14px;
}
.Call-Navigation .switch {
  display: flex;
  gap: 11px;
  position: relative;
  align-items: center;
  width: fit-content;
  margin-bottom: 8px;
}
.Call-Navigation .switch .in {
  height: 12px;
  width: 32px;
  border-radius: 6px;
  background-color: #e6e9ed;
  display: flex;
  align-items: center;
}

.Call-Navigation .switch input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.Call-Navigation .switch .in::before {
  content: "";
  background-color: #c2c2c2;
  height: 20px;
  width: 20px;
  display: flex;
  border-radius: 50%;
}

.Call-Navigation .switch input:checked ~ .in::before {
  background-color: #377dff;
  margin-left: auto;
}

.Call-Navigation .line {
  background-color: #c2c2c2;
  width: 1px;
  height: 143px;
  margin: auto 0;
  max-width: 1px;
  display: none;
}
.Call-Navigation a.button {
  background: #000;
  /* max-width: 350px; */
  width: 100%;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;
}

.Call-Navigation a.button svg {
  font-size: 24px;
}

.Call-Navigation .call-card .copy {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 60px;
  justify-content: center;
  gap: 20px;
  white-space: nowrap;
  flex-direction: column;
}

.Call-Navigation .call-card .copy button {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Call-Navigation .call-card .copy span {
  border-bottom: solid 1px #676767;
}

.Call-Navigation h2 {
  font-size: 47px;
  font-weight: bold;
}
.Call-Navigation .swiper {
  max-width: 1400px;
}
.Call-Navigation .more-inf {
  display: flex;
  align-items: center;
  margin-top: 100px;
  gap: 20px;
}

.Call-Navigation .more-inf img {
  object-fit: contain;
}
.Call-Navigation .more-inf > * {
  flex: 3;
}

.Call-Navigation .more-inf > div {
  flex: 2;
}

.Call-Navigation .swiper-pagination {
  display: flex;
  gap: 6px;
  height: 5px;
  justify-content: center;
}

.Call-Navigation .swiper-pagination .swiper-pagination-bullet {
  background-color: #cccccc;
  width: 64px;
  border-radius: 2.5px;
}

.Call-Navigation
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #000;
}
@media (max-width: 1440px) {
  .Call-Navigation .call-card {
    flex-direction: column;
    width: 400px;
    max-width: 100%;
  }
  .Call-Navigation .call-card > * {
    width: 100% !important;
    max-width: 100%;
  }
  .Call-Navigation .call-card .type {
    flex-direction: row;
  }
  .Call-Navigation .call-card .copy {
    flex-direction: row;
  }
  .Call-Navigation .call-card .type h4 svg {
    transform: scale(0.7);
  }
}

@media (max-width: 1200px) {
  .Call-Navigation .swiper {
    max-width: 400px;
    margin: 0;
  }
  .Call-Navigation .more-inf {
    flex-direction: column;
    max-width: 400px;
  }
}
