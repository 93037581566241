.Tracking .right-info {
  width: 400px;
  max-width: 100%;
  min-width: 400px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.Tracking .right-info > * {
  padding: 16px 20px;
  box-shadow: 8.2px 8.2px 24.61px 0px #0202460d;
  background-color: white;
  margin-bottom: 1px;
}

.Tracking .right-info > *:first-child,
.Tracking .right-info > *:last-child {
  box-shadow: none;
  background: none;
}

.Tracking .right-info p,
.Tracking .right-info a {
  color: #667085;
  font-size: 14.35px;
  font-weight: 400;
  line-height: 20.51px;
  text-align: left;
}

.Tracking .right-info p.bold {
  color: #0a0d13;
}

.Tracking .right-info a {
  color: #377dff;
  text-decoration: underline;
  margin-left: auto;
}

.Tracking .right-info .flex {
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.Tracking .right-info svg {
  font-size: 30px;
  padding: 3px;
  min-width: 32px;
}

.Tracking .right-info .add {
  white-space: nowrap;
  max-width: 80%;
  margin: auto;
}
.Tracking .right-action {
  margin-top: auto;
}

@media (max-width: 920px) {
  .Tracking .right-info {
    max-width: 100%;
    width: 100%;
  }
  .Tracking .right-info .right-action {
    position: sticky;
    bottom: 0;
    background-color: #f3f4f8;
  }
}

@media (max-width: 560px) {
  .Tracking .right-info {
    min-width: auto;
  }
}
