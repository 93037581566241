.RendezVous {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.RendezVous .calendar-container {
  background-color: #f5f9ff;
  border-radius: 20px;
  overflow: hidden;
  flex: 1;
  min-width: 300px;
}

.RendezVous .calendar-container .top {
  color: white;
  background-color: #377dff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.RendezVous .calendar-container .top img {
  height: 70px;
  object-fit: contain;
}

.RendezVous .calendar-container .top > * {
  flex: 1;
}

.RendezVous .calendar-container .body {
  padding: 15px;
  padding-bottom: 5px;
}

.RendezVous .calendar-container .body a {
  color: #377dff;
  text-align: center;
  width: fit-content;
  margin: auto;
  display: flex;
}

.Demandes {
  padding: 24px;
  background: #ffffff;
  border-radius: 14.69px;
  width: 100%;
}

.Demandes h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 34.28px;
  text-align: left;
  color: #004225;
}

.Demandes > button,
.Demandes > a {
  font-size: 15px;
  font-weight: 500;
  line-height: 19.59px;
  text-align: center;
  color: #3595ff;
  margin: auto;
  margin-top: 24px;
  text-align: center;
  display: flex;
  width: fit-content;
  text-decoration: underline;
}

.Demands-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
}

.Demande {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  border: solid 1px #359dff;
  border-radius: 10px;
  background-color: #377dff0d;
  gap: 20px;
  flex: 1;
  width: 100%;
}

.Demande > p,
.Demande > h3 {
  min-width: 260px;
}

.Demande.disabled {
  background-color: #ff37370d;
}

.Demande h3 {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 19px;
  font-weight: 500;
  line-height: 24.68px;
  text-align: left;
  color: #004225;
}

.Demande h3 svg {
  font-size: 20px;
  min-width: 20px;
}

.Demande p {
  font-size: 19px;
  font-weight: 400;
  color: #004225;
}

.Demande p span {
  color: #359dff;
  font-weight: 700;
}

.Demande button,
.Demande a {
  background-color: #377dff;
  color: white;
  padding: 14px 30px;
  border-radius: 10px;
  text-align: center;
}

.Demande a.annule {
  color: #ff3737;
  background: transparent;
  font-weight: 600;
  pointer-events: none;
}

.Demande.accepted {
  background-color: #ccfde8;
}

.Demande a.accepted {
  color: #01b869;
  background: transparent;
  font-weight: 600;
  pointer-events: none;
}

.Gestion .top {
  background-color: #377dff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 50px 50px 0 0;
  color: white;
  font-size: 30px;
  font-weight: 500;
  line-height: 39.88px;
  text-align: center;
}

.Gestion .body {
  background-color: #f5f9ff;
  min-height: 100px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.Gestion .body .body-container {
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  min-width: min(400px, 100%);
}

.Gestion .body h4 {
  font-size: 34.89px;
  font-weight: 500;
  line-height: 39.88px;
  text-align: center;
  color: #377dff;
}

.Gestion .body .flex {
  margin-top: 20px;
  gap: 40px;
}

.Gestion .body .flex select {
  background-color: transparent;
  font-weight: 700;
}

.Gestion .body .flex select:first-child {
  color: #377dff;
  padding: 10px;
}

.Gestion .body .times {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.Gestion .body .input-container {
  min-width: fit-content;
  width: 200px;
  flex: inherit;
}

.Gestion .img-container {
  background-color: white;
  width: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  position: sticky;
  top: 0;
  justify-content: space-evenly;
}

.Gestion .img-container img {
  width: 250px;
}

.Gestion .img-container button {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.64px;
  text-align: left;
  width: fit-content;
  white-space: nowrap;
  max-width: 300px;
  padding: 0 10px;
}

.Demande .form {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 60px;
  display: flex;
  gap: 30px;
  padding: 24px;
  justify-content: center;
  flex-wrap: wrap;
}

.Demande .form img {
  object-fit: contain;
  max-width: 100%;
  width: 400px;
  margin: auto;
}

.Demande .form form {
  flex-direction: column;
  max-width: 400px;
}

.Demande .form form .input-container {
  width: 100%;
}

.Demande-Page .Demande {
  flex-wrap: wrap;
  max-width: 1000px;
}

.Demande-Page .Demande p {
  margin: auto;
}

.Demande .form form .flex button {
  flex: 1;
  max-width: inherit;
}

.Demande .form form .flex button.white {
  background: white;
  border: 0.8px solid #dbdfed;
  color: #393939;
}

.Demande .nb {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  background-color: white;
  border-radius: 15px;
  margin-top: 20px;
}

.Demande .nb > * {
  color: #3d3b3b;
  font-size: 12.56px;
  font-weight: 400;
  line-height: 18.85px;
  text-align: left;
}

.Demande .nb svg {
  min-width: 20px;
  font-size: 20px;
}

.Demande .body {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  width: 100%;
}

.Demande .body img {
  flex: 1;

  object-fit: contain;
}

.Demande .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: min(100%, 300px);
}

.Demande .right section {
  background-color: #f3f4f8;
  border-radius: 5px;
  width: 100%;
  padding: 10px 14px;
}

.Demande .right section h5 {
  color: black;
  font-size: 18px;
  font-weight: 700;
  line-height: 18.85px;
  text-align: left;
}

.Demande .right section h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.85px;
  text-align: left;
  margin-top: 20px;
}

.Demande .right section p {
  color: #898989;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.85px;
  text-align: left;
}

.Demande .right section h4 {
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.85px;
  text-align: left;
}

.Demande .right section span {
  font-size: 14px;
  font-weight: 600;
}

.Demande .right section .flex {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.Demande .right section .flex h6 {
  margin-top: 0;
}

.Demande .right .buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.Demande .right .buttons button {
  min-width: 100px;
  max-width: 160px;
  flex: 1;
}

.Demande .right .buttons button.white {
  border: 1px solid #39393930;
  background-color: transparent;
  color: #393939;
}

@media (max-width: 920px) {
  .Demande {
    flex-direction: column;
    align-items: flex-start;
    min-width: 280px;
    gap: 5px;
  }
  .Demande .form {
    margin-top: 20px;
  }
  .Demande h3 {
    font-size: 16px;
  }
  .Demande h3 svg {
    font-size: 17px;
  }
  .Demande button,
  .Demande a {
    width: 100%;
    margin-top: 10px;
  }
  .Demande p {
    font-size: 16px;
  }
}

@media (max-width: 560px) {
  .calendar-container .top div {
    display: none;
  }
  .Demande {
    flex-direction: column;
    align-items: flex-start;
  }
  .Demande button,
  .Demande a {
    width: 100%;
  }
  .Gestion .body .body-container {
    padding: 40px 10px;
  }
  .Gestion .body .body-container h4 {
    font-size: 25px;
  }
  .Gestion .body .input-container {
    width: 250px;
  }
  .body-container .input-container .check-box {
    /* font-size: 12px; */
  }
}
