 .Search {
     display: flex;
     background-color: #FAFAFA;
     height: 48px;
     width: 320px;
     position: relative;
     border-radius: 8px;
     overflow: hidden;
     min-width: 200px;
 }
 
 .Search input {
     height: 100%;
     width: 100%;
     padding-left: 46px;
     background-color: transparent;
 }
 
 .Search input::placeholder {
     color: #B8BDC5;
 }
 
 .Search svg {
     position: absolute;
     top: 50%;
     left: 16px;
     transform: translateY(-50%);
     font-size: 20px;
     color: #21252B;
 }