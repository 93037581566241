.flex-link {
  display: flex;
  gap: 20px;
}

.flex-link input,
.flex-link button {
  flex: 1;
  min-height: 73px;
  padding: 0 24px;
  gap: 0px;
  border-radius: 24.18px;
}
.flex-link input {
  padding-left: 45px;
  font-weight: 400;
}

.flex-link .input {
  position: relative;
  flex: 1;
  display: flex;
}
.flex-link .input svg {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #555;
}

.flex-link button {
  flex: none;
  background: #377dff;
  color: white;
  min-width: 200px;
}
.iframe {
  padding: 16px;
  background-color: white;
  border-radius: 24.18px;
  margin-top: 20px;
  position: relative;
}
.iframe iframe {
  min-height: 600px;
  width: 100%;
  border-radius: 16px;
  /* background-color: #eee; */

  background-image: url('../../../public/home.png');
}

@media (max-width: 720px) {
  .flex-link input,
  .flex-link button {
    min-height: 50px;
    padding: 0 16px;
    gap: 0px;
    border-radius: 16px;
  }
  .flex-link input {
    padding-left: 40px;
  }
  .flex-link button {
    min-width: 120px;
  }
  .iframe {
    margin-top: 16px;
  }
}
@media (max-width: 500px) {
  .flex-link {
    flex-direction: column;
  }
  .flex-link button {
    flex: 1;
  }

  .iframe {
    padding: 8px;
  }

  .iframe iframe {
    min-height: 600px;
  }
}
