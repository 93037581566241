.Paiement .details {
    box-shadow: 0px 0px 1px 0px #21252952;
    box-shadow: 0px 4px 6px 0px #21252933;
    padding: 32px;
    background-color: white;
    margin-top: 25px;
    border-radius: 20px;
}

.Paiement .card-info {
    border: 0.78px solid #E4E7EC;
    padding: 13px;
    padding-bottom: 0;
    border-radius: 6px;
    margin-top: 11px;
    gap: 0px;
    position: relative;
}

.Paiement .card-info p {
    font-size: 12px;
}

.Paiement .card-info p .bold {
    font-size: 14px;
}

.Paiement .card-info:has(input:checked) {
    background: #EFF0F7;
    border-color: #020246;
}

.Paiement .card-info .card-type-details {
    display: none;
}

.Paiement .card-info:has(input:checked) .card-type-details {
    display: block;
}

.Paiement .check-box input {
    /* width: 500px; */
    height: 60px;
    /* transform: translate(-20px, -10px); */
}

.Paiement .check-box {
    flex: 1;
}

.Paiement .card-info .out {
    border-radius: 50%;
    background: transparent;
    border: 0.78px solid #D0D5DD;
    min-width: 20px;
    cursor: pointer !important;
}

.Paiement .card-info input:checked~.out {
    background-color: transparent;
}

.Paiement .card-info input:checked~.out svg {
    display: flex;
    height: 24px;
    width: 24px;
}

.Paiement .card-info .check-box {
    align-items: flex-start;
    gap: 10px;
}

.Paiement .card-info .out svg {
    display: none;
}

.Paiement .card-info svg {
    font-size: 18px;
    min-width: 18px;
}

.Paiement .card-type {
    margin-left: auto;
    display: flex;
    gap: 10px;
    color: #344054;
}

.Paiement .card-type img {
    height: 34px;
    min-width: 60px;
}

.Paiement .card-info .flex.full {
    margin-bottom: 20px;
}

.Paiement .selected-card-type {
    padding-left: 60px;
    background-image: url('../../../../public/images/mastercard.svg');
    background-size: 60px;
    background-position: 0 center;
    background-repeat: no-repeat;
}

.Paiement .flex.lock {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    font-size: 12px;
    color: #020246;
}

.Paiement .flex.lock div {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    min-width: 30px;
    background-color: #F2F4F7;
    border: 1.56px solid #FCFCFD;
    padding: 5px;
    font-size: 16px;
    color: #828282;
}

.Paiement .details>img {
    height: 60px;
    margin: auto;
    margin-top: 40px;
    display: flex;
}

.Paiement .payment-details-container {
    position: sticky;
    top: 120px;
    flex: 1;
    max-width: 500px;
    min-width: 400px;
    height: fit-content;
}

.Paiement .paymen-details {
    background-image: url('../../../../public/images/bg-filter.svg');
    flex: 1;
    padding: 63px 37px 37px;
    border: 1px solid #E3E3E3;
    background-color: #FFFFFF;
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Paiement .paymen-details h3 {
    font-size: 44.28px;
    font-weight: 700;
    line-height: 55.79px;
    text-align: left;
    margin-top: 11px;
    margin-bottom: 40px;
}

.Paiement .paymen-details h3 span {
    font-size: 32px;
}

.Paiement .paymen-details .flex {
    justify-content: space-between;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
}

.Paiement .paymen-details .flex p {
    font-size: 13px;
}

.Paiement .paymen-details .flex p .bold {
    font-size: 17px;
}

.Paiement .paymen-details ul {
    list-style: circle;
    width: 100%;
}

.Paiement .paymen-details ul li span {
    padding-left: 30px;
}

.Paiement .paymen-details .line {
    width: 100%;
    border: solid 1px #747474;
}

.Paiement .paymen-details .flex.full:last-child {
    margin-bottom: 0;
    margin-top: 20px;
}

.payment-details-container button {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
}

.card-type-details .min input,
.card-type-details .min.input-container {
    max-width: 100px;
    min-width: 100px;
}

@media (max-width:560px) {
    .Paiement .details {
        padding: 20px;
        border-radius: 10px;
    }
    .Paiement .details .card-type {
        margin: auto;
    }
    .Paiement .flex.full .input-container {
        max-width: 100% !important;
    }
    .Paiement .paymen-details .flex p {
        font-size: 12px;
    }
    .Paiement .paymen-details .flex p .bold {
        font-size: 15px;
    }
    .Paiement .payment-details-container {
        min-width: 100%;
    }
}