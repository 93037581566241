.ProfileHeader {
    position: relative;
}

.ProfileHeader .user img {
    height: 32px;
    width: 32px;
    min-width: 32px;
    object-fit: cover;
    border-radius: 50%;
}

.ProfileHeader .overlay {
    display: block !important;
    background-color: #33415510;
    left: 0;
    top: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9;
}

.ProfileHeader .list {
    position: absolute;
    right: 0;
    top: 120%;
    min-width: 200px;
    background-color: white;
    box-shadow: 0px 4px 6px -2px #0000000D;
    border: 1px solid #E2E8F0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 10;
}

.ProfileHeader .list button,
.ProfileHeader .list a {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #E2E8F0;
    padding-left: 12px;
    display: flex !important;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #334155;
}

.ProfileHeader .list button:hover,
.ProfileHeader .list a:hover {
    background-color: #94A3B8;
    color: white;
}

.ProfileHeader .list button:last-child,
.ProfileHeader .list a:last-child {
    border-bottom: none;
}

.ProfileHeader .list button:hover svg,
.ProfileHeader .list a:hover svg {
    color: white;
}

.ProfileHeader .list button img,
.ProfileHeader .list a img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.ProfileHeader .list button svg,
.ProfileHeader .list a svg {
    font-size: 16px;
    color: #94A3B8;
}