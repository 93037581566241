.Propriete {
    display: flex;
    gap: 40px;
    width: 100%;
    justify-content: flex-start;
    max-width: 100%;
}

.Propriete .swiper-container {
    position: relative;
    /* flex: 1; */
    height: 550px;
    width: 50%;
    min-width: 500px;
    max-width: 600px;
}

.Propriete .swiper {
    width: 100%;
    height: 100%;
}

.Propriete .swiper .swiper-slide {}

.Propriete .swiper .swiper-slide img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.Propriete .pagination-swiper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    /* padding: 11px; */
    background-color: white;
    border: solid 11px white;
    padding-bottom: 11px;
    overflow: hidden;
    gap: 10px;
    justify-content: space-between;
    border-radius: 65px 65px 6px 6px;
    max-width: calc(100% - 40px);
    width: fit-content;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: none;
}

.Propriete .pagination-swiper::-webkit-scrollbar {
    /* display: none; */
}

.Propriete .pagination-swiper .p-img {
    display: flex;
    position: relative;
    width: 80px;
    height: 80px;
    min-width: 80px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    /* padding-top: 100%; */
}

.Propriete .pagination-swiper .p-img.active::after {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #377DFF;
    border: solid 1px #eee;
    position: absolute;
    z-index: 6;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.Propriete .pagination-swiper .p-img img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
}

.Propriete-tab {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
}

.Propriete-tab .add {
    margin-top: 40px;
    margin-left: auto;
}

.Propriete-button {
    width: 300px !important;
    max-width: 100% !important;
    margin-left: auto;
    margin-top: 40px;
    display: none;
}

@media (max-width:1024px) {
    .Propriete {
        flex-wrap: wrap;
    }
    .Propriete .swiper-container {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width:560px) {
    .Propriete .swiper-container {
        height: 400px;
        min-width: 100%;
    }
    .Propriete .pagination-swiper {
        border-radius: 40px 40px 6px 6px;
        border: solid 8px white;
        border-bottom: none;
        padding-bottom: 8px;
        max-width: calc(100% - 20px);
        bottom: 8px;
    }
    .Propriete .pagination-swiper .p-img {
        height: 60px;
        width: 60px;
        min-width: 60px;
    }
    .Propriete-button {
        margin-left: 0;
        width: 100% !important;
        /* max-width: 300px !important; */
    }
}