.FormPropriete {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.FormPropriete>div {
    flex: 1;
    min-width: min(100%, 400px);
    max-width: fit-content;
}

.FormPropriete h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    text-align: left;
    text-transform: capitalize;
}

.FormPropriete form {
    margin-top: 22px;
    background-color: white;
    padding: 15px 28px 15px 28px;
    gap: 22px;
    border-radius: 10px;
}

.FormPropriete form.no-bg {
    padding: 0;
    background: transparent;
}

.FormPropriete .flex-upload {
    margin-top: 10px;
    min-width: 100%;
    flex-direction: row;
    position: relative;
}

.FormPropriete .flex-upload input {
    position: absolute;
    height: 100px;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0;
}

.FormPropriete form .upload {
    height: 90px;
    width: 90px;
    background-color: #377DFF14;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.FormPropriete form .upload.upload-button {
    width: fit-content;
    overflow: visible;
    cursor: pointer;
    border: dashed 1px #377DFF;
}

.FormPropriete form .upload img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.FormPropriete form .upload button {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: #0005;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    font-size: 10px;
    cursor: pointer;
}

.FormPropriete .line {
    border-bottom: solid 1px #E4E7EB;
    width: 100%;
}

.FormPropriete .check-box {
    gap: 10px;
    font-size: 14px;
}

.FormPropriete .check-box .out {
    height: 18px;
    width: 18px;
}

.check-box svg {
    font-size: 18px;
}

.more-details {
    max-width: 100% !important;
    flex: 1;
}

.more-details h2 {
    margin-bottom: 16px;
    margin-top: 16px;
}

.more-details img {
    height: 246px;
}

.more-details .flex {
    gap: 15px;
}

.more-details .flex button,
.more-details .flex a {
    height: 44px;
}

.more-details .flex a {
    background-color: red;
}

.FormPropriete .flex .par {
    min-width: 60px;
}