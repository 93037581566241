.tabs-container {
  width: 100%;
  display: flex;
  gap: 15px;
  background-color: white;
  border-radius: 30px;
  padding: 7px;
  max-width: 500px;
  margin-bottom: 24px;
  overflow: auto;
  /* flex-wrap: wrap; */
}

.tabs-container::-webkit-scrollbar {
  display: none;
}

.tabs-container button {
  padding: 6px 10px;
  font-family: Outfit;
  font-size: 15px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  border-radius: 30px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 13.2px 0px #00000014;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.tabs-container button.active {
  border: none;
  background-color: #377dff;
  color: white;
}

.Apercu {
  flex: 1;
  max-width: 100%;
}

.Apercu .flex {
  align-items: center;
  gap: 17px;
}

.Apercu h2 {
  font-size: 35.29px;
  font-weight: 700;
  line-height: 52.94px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000929;
}

.Apercu h3 {
  font-size: 27.31px;
  font-weight: 800;
  line-height: 40.97px;
  letter-spacing: -1.1379311084747314px;
  text-align: left;
  color: #377dff;
}

.Apercu h3 span {
  font-size: 17.07px;
  line-height: 25.6px;
  letter-spacing: -1.1379311084747314px;
  text-align: left;
}

.Apercu h3 span:last-child {
  color: #000929a0;
  font-weight: 500;
}

.Apercu h4 {
  font-size: 18.21px;
  font-weight: 700;
  line-height: 27.31px;
  text-align: left;
  color: #000929a0;
  margin-top: 10px;
  margin-bottom: 5px;
}

.Apercu p {
  text-align: justify;
  margin-top: 10px;
  max-width: 100%;
}

.Apercu button {
  display: flex;
  border: 1px solid #c8c8c8;
  padding: 6px;
  border-radius: 6px;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  gap: 10px;
  color: #000929;
}

.Apercu button svg {
  width: 18.97px;
  height: 18.02px;
  top: 2.84px;
  left: 1.9px;
  gap: 0px;
  color: #377dff;
}

.Apercu .flex-icons {
  display: flex;
  gap: 18px;
  margin-top: 28px;
  flex-wrap: wrap;
}

.Apercu .flex .item {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #000929a0;
  font-size: 16px;
  font-weight: 500;
  line-height: 16.17px;
  text-align: left;
}

.Apercu .flex .item svg {
  color: #377dff;
  font-size: 20px;
}

.photo-3d {
  margin: auto;
  margin-top: 28px;
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.Propriete-tab img {
  max-height: 500px;
  object-fit: contain;
}

.map-container {
  height: 400px;
  flex: 1;
}

@media (max-width: 1024px) {
  .Apercu {
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width: 560px) {
  .tabs-container {
    gap: 5px;
  }
  .tabs-container button {
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}
