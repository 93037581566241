.FormUser {
  background-color: #f9fafb;
  padding: 34px;
  border-radius: 20px;
  max-width: 900px;
}

.FormUser .img-user {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
}

.FormUser .img-user img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  border: dashed 1px #377dff;
}

.FormUser .img-user button {
  padding: 5px 10px;
  font-size: 11.16px;
  font-weight: 400;
  line-height: 15.94px;
  text-align: center;
  border: 0.8px solid #1a1a1a;
  border-radius: 13px;
  display: flex;
  gap: 3px;
  align-items: center;
}

.FormUser .img-user button:last-child {
  border-color: transparent;
}

.FormUser form button {
  margin-right: auto;
}

.FormUser form button div {
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
}

.FormUser form button span {
  margin-right: auto;
  display: flex;
  width: 100%;
  font-size: 12px;
  font-weight: 200;
  line-height: 11.33px;
  text-align: left;
  margin-top: 5px;
}

.FormUser form button div svg {
  font-size: 20px;
  color: #646464;
}

.FormUser .uploads {
  display: flex;
  position: relative;
  width: fit-content;
}

.FormUser .uploads img {
  height: 40px;
}

.FormUser .uploads button {
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
  background-color: #f00;
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  transform: translate(50%, -50%);
}

@media (max-width: 560px) {
  .FormUser {
    padding: 34px 24px;
  }
  .FormUser .img-user img {
    height: 80px;
    width: 80px;
    min-width: 80px;
  }
}
