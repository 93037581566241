.Dashboard .counts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 29px;
  margin-top: 16px;
}

.Dashboard .counts .count {
  border-radius: 10px;
  height: 98px;
  min-width: 240px;
  flex: 1;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.Dashboard .counts .count svg {
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-color: #e7ebeb;
  border-radius: 50%;
  padding: 7px;
  color: #2056ff;
}

.Dashboard .counts .count:hover {
  box-shadow: 0px 4px 4px 0px #00000020;
  border: 1px solid #000000;
}

.Dashboard .counts .count .data {
  flex: 1;
}

.Dashboard .counts .count .data h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  margin-bottom: 13px;
  color: #8f8f8f;
}

.Dashboard .counts .count .data .flex {
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
}

.Dashboard .counts .count .data span {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: right;
}

.Dashboard .counts .count .data .success {
  color: #04b700;
}

.Dashboard .counts .count .data .danger {
  color: #ff481fcc;
}

@media (max-width: 1400px) {
  .Dashboard .counts {
    gap: 20px;
  }
  .Dashboard .counts {
    /* max-width: 800px; */
  }
  .Dashboard .counts .count {
    min-width: max(40%, 240px);
  }
}

@media (max-width: 768px) {
  .Dashboard .counts .count {
    min-width: 240px;
  }
}
