        .Login {
            display: flex;
            gap: 40px;
            background: linear-gradient(#F3F4F8 4.23%, #FFF 84.42%);
            position: relative;
            left: 0;
            top: 0;
            right: 0;
            height: 100dvh;
            min-height: 700px;
            align-items: stretch;
            overflow: auto;
        }
        
        .Login.Register {
            min-height: 1000px;
            overflow: inherit;
        }
        
        .Login .Info-container {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            position: sticky;
            top: 0px;
            height: 100%;
            max-height: 100vh;
        }
        
        .Login .Info {
            padding: 0 40px;
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
        }
        
        .Login .Logo {
            margin: auto;
        }
        
        .Login h1 {
            font-family: Outfit;
            font-size: 34.69px;
            font-weight: 400;
            line-height: 46px;
            text-align: center;
            color: #000;
        }
        
        .Login h1 span {
            position: relative;
            display: inline-block;
            padding: 0 2px;
            z-index: 1;
        }
        
        .Login h1 span::before {
            content: '';
            height: 10px;
            width: 100%;
            background-color: #377DFF8F;
            left: 0;
            border-radius: 5px;
            bottom: 15%;
            position: absolute;
            z-index: -1;
        }
        
        .Login h5 {
            font-family: Outfit;
            font-size: 20.81px;
            font-weight: 300;
            line-height: 26.22px;
        }
        
        .Login .new-account {
            margin-top: 54px;
        }
        
        .Login .new-account h6,
        .Login .new-account a {
            font-family: Outfit;
            font-size: 16.82px;
            font-weight: 200;
            line-height: 21.19px;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 6px;
            justify-content: center;
        }
        
        .Login .new-account a span {
            border-bottom: solid 1px #000;
        }
        
        .Login .new-account a svg {
            font-size: 17px;
        }
        
        .Login .home-3d {
            position: relative;
            overflow: hidden;
            max-height: calc(100dvh - 550px);
        }
        
        .Login .home-3d img {
            min-width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            display: block;
        }
        
        .Login .home-3d::after {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(175.83deg, #FFFFFF00 50%, #F3F4F850 84.42%);
            content: '';
            z-index: 10;
        }
        /* Form */
        
        .Login .Form-container {
            flex: 1;
            border-radius: 20px;
            background: url('../../assets/images/login-bg.jpeg');
            background-position: center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            margin: 12px;
            max-width: 720px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .Login .Form-container::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: #000A;
            z-index: 1;
        }
        
        .Login form {
            padding: 48px;
            gap: 32px;
            border-radius: 20px;
            background: var(--background-mode, #FFFFFF);
            box-shadow: 40px 40px 60px 0px #E4E6EA1D;
            position: relative;
            z-index: 100;
            width: 500px;
            max-width: 90%;
        }
        
        .Login .form-items {
            max-width: 100%;
            width: 100%;
            margin: auto;
        }
        
        .Login form h2 {
            font-family: Outfit;
            font-size: 28px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
        }
        
        .Login .inputs {
            margin: 32px 0;
            width: 100%;
            max-width: 100%;
        }
        
        .Login .inputs .input-container {
            width: 100%;
            margin-bottom: 24px;
            position: relative;
        }
        
        .Login .inputs .flex {
            flex-direction: row;
        }
        
        .Login .inputs .show-password {
            position: absolute;
            right: 0;
            bottom: 3px;
            height: 40px;
            width: 60px;
            cursor: pointer;
            font-size: 24px;
            color: #98A2B3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .Login .inputs .input-container:last-child {
            margin-bottom: 0;
        }
        
        .Login .inputs {
            font-family: Outfit;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #344054;
        }
        
        .Login .flex {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        
        .Login .flex:has(.input-container) {
            margin-bottom: 24px;
        }
        
        .Login .flex .input-container {
            margin-bottom: 0;
            min-width: 160px;
            flex: 1;
        }
        
        .Login .flex span {
            font-family: Outfit;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: #1570EF;
            cursor: pointer;
        }
        
        .Login input,
        .Login button,
        .Login .checkboxs {
            /* display: block; */
            width: 100%;
            height: 48px;
            padding: 12px 16px 12px 16px;
            gap: 5px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            margin-top: 12px;
            min-width: 100px;
        }
        
        .Login .checkboxs {
            height: fit-content;
        }
        
        .Login .input-container .flex {
            flex-direction: row;
        }
        
        .Login button {
            margin-top: 0;
            margin-bottom: 24px;
            font-family: Outfit;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background-color: #D1E9FF;
            border: none;
        }
        
        .Login button svg {
            font-size: 20px;
        }
        
        .Login .buttons h4 {
            font-family: Outfit;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            display: flex;
            justify-content: center;
            margin: auto;
            gap: 8px;
            color: #98A2B3;
            flex-wrap: wrap;
        }
        
        @media (max-width:1024px) {
            .Login {
                flex-direction: column-reverse;
                padding: 12px;
            }
            .Login .Form-container,
            .Login .Info-container {
                width: 100%;
                max-width: 100%;
            }
            .Login .Info-container {
                min-height: 800px;
                display: none;
            }
            .Login .Form-container {
                min-height: 100%;
                margin: 0;
            }
        }
        
        @media (max-width:560px) {
            .Login {
                padding: 0px;
                margin: 0;
                min-height: 100dvh;
                height: fit-content;
            }
            .Login.Register {
                min-height: 100dvh;
                height: fit-content;
            }
            .Login .Form-container {
                border-radius: 0;
                min-height: 100%;
                background: none;
            }
            .Login form {
                max-width: 100%;
                width: 100%;
                min-height: 100dvh;
                border-radius: 0;
                padding: 48px 40px;
                display: flex;
                align-items: center;
                margin: 0;
            }
            .Login form .form-items {
                width: 100%;
            }
            .Login .inputs .checkboxs {
                gap: 15px;
            }
        }