.SideBar {
  width: 276px;
  background-color: white;
  border-right: solid 1px #dbdfed;
  padding: 31px 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  overflow: auto;
}

.SideBar::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1024px) {
  .app-container:has(.show_logo_only) .SideBar {
    position: fixed;
    z-index: 99;
    background-color: transparent;
  }
  .app-container:has(.show_logo_only) .SideBar menu {
    display: none;
  }
}

.SideBar .Logo {
  width: 100%;
}

.SideBar .Logo img {
  width: 100%;
  max-height: 60px;
  object-fit: contain;
  cursor: pointer;
}

.SideBar .Logo img:last-child {
  display: none;
}

.SideBar menu {
  flex: 1;
  width: 100%;
  overflow: auto;

  margin-top: 20px;
}
.SideBar menu::-webkit-scrollbar {
  display: none;
}

.SideBar menu ul {
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: 100%;
}

.SideBar menu ul li {
  width: 100%;
}

.SideBar menu ul li:last-child {
  margin-top: auto;
}

.SideBar menu ul li a,
.SideBar menu ul li button {
  width: 100%;
  height: 42px;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 11px;
  border-radius: 3.13px;
  font-family: Outfit;
  font-size: 14.59px;
  font-weight: 500;
  line-height: 18.39px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #c4c4c4;
  position: relative;
  overflow: hidden;
  position: relative;
}

.SideBar menu ul li a.active {
  background-color: #377dff;
  color: #ffffff;
}

.SideBar menu ul li a span,
.SideBar menu ul li a svg {
  position: relative;
  z-index: 1;
}

.SideBar menu ul li a::after,
.SideBar menu ul li button::after {
  content: '';
  background-color: #377dff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  z-index: 0;
  transform: translate(-50%, -50%) scale(0);
}

.SideBar menu ul li a:hover::after,
.SideBar menu ul li button:hover::after {
  transform: translate(-50%, -50%) scale(18);
  transition: all 0.4s;
}

.SideBar menu ul li a:hover,
.SideBar menu ul li button:hover {
  box-shadow: 0px 0px 10px #00000005;
  color: white;
}

.SideBar menu ul li a svg,
.SideBar menu ul li button svg {
  font-size: 17px;
}

.overlay {
  display: none;
}

.SideBar .close-menu {
  display: none;
}

@media (max-width: 1024px) {
  .SideBar {
    z-index: 99;
    width: 72px;
    transition: all 0.4s;
    padding-top: 15px;
  }
  .SideBar.active {
    width: 276px;
    padding-top: 31px;
  }
  .SideBar .Logo img:last-child {
    display: block;
  }
  .SideBar .Logo img:first-child {
    display: none;
  }
  .SideBar.active .Logo img:first-child {
    display: block;
  }
  .SideBar.active .Logo img:last-child {
    display: none;
  }
  .SideBar li a,
  .SideBar li button {
    justify-content: center;
  }
  .SideBar.active li a,
  .SideBar.active li button {
    justify-content: flex-start;
  }
  .SideBar li span {
    display: none;
  }
  .SideBar.active li span {
    display: block;
  }
}

@media (max-width: 720px) {
  .SideBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99;
    width: 276px;
    transform: translateX(-100%);
    transition: all 0.4s;
    padding-top: 31px;
    overflow: inherit;
  }
  .SideBar.active {
    transform: translateX(0%);
    /* position: relative; */
  }
  .SideBar .Logo img:first-child {
    display: block;
  }
  .SideBar .Logo img:last-child {
    display: none;
  }
  .SideBar li a,
  .SideBar li button {
    justify-content: flex-start;
  }
  .SideBar li span {
    display: block;
  }
  .SideBar.active ~ .overlay {
    display: flex;
    position: absolute;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #0001;
    z-index: 98;
  }
  .SideBar.active .close-menu {
    height: 40px;
    width: 40px;
    background-color: white;
    border: solid 1px #eeeeee;
    position: fixed;
    right: 0;
    top: 30px;
    transform: translateX(50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }
}
