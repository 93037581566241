.FicheIntervention {
    display: flex;
    background-color: white;
    border-radius: 14px;
    width: 100%;
    align-items: center;
    overflow: hidden;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    align-items: center;
}

.FicheIntervention .flex-left {
    display: flex;
    background-color: #FBFAFF;
    align-items: center;
    padding: 20px;
    gap: 13px;
    height: 100%;
}

.FicheIntervention .flex-left .timer {
    background-color: #5051F9;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
}

.FicheIntervention .flex-left p {
    display: flex;
    align-items: center;
    font-size: 12.9px;
    font-weight: 400;
    line-height: 16.25px;
    text-align: left;
    margin-top: 10px;
    gap: 5px;
    color: #778399;
}

.FicheIntervention .flex-left p svg {
    font-size: 20px;
}

.FicheIntervention .flex-left h5 {
    font-size: 14.33px;
    font-weight: 600;
    line-height: 18.06px;
    text-align: center;
}

.FicheIntervention .flex-center {
    padding: 10px;
    flex: 1;
}

.FicheIntervention .flex-center:last-child {
    flex: none;
}

.FicheIntervention .flex-center h4 {
    font-size: 16.22px;
    font-weight: 500;
    line-height: 20.44px;
    text-align: left;
    color: #000000;
}

.FicheIntervention .flex-center .flex {
    display: flex;
    gap: 10px;
    color: #768298;
}

.FicheIntervention .flex-center .flex svg {
    margin-top: 2px;
    font-size: 18px;
    min-width: 18px;
}

.FicheIntervention .flex-center span {
    color: #2056FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 13.24px;
    letter-spacing: -0.025em;
    text-align: left;
    max-width: 350px;
    display: flex;
    margin: 12px 0;
}

.FicheIntervention .flex-center h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22.49px;
    font-weight: 400;
    line-height: 28.34px;
    letter-spacing: -0.025em;
}

.FicheIntervention .flex-center .slider {
    height: 5px;
    width: 200px;
    border-radius: 5px;
    background-color: #E3E8EE;
    margin: auto;
    overflow: hidden;
}

.FicheIntervention .flex-center .slider div {
    width: 15%;
    background-color: #2056FF;
    height: 100%;
    border-radius: 5px;
}

.FicheIntervention .add {
    color: white;
    background-color: #2056FF;
    margin-right: 30px;
    min-width: 160px;
}

.FicheIntervention .add.light {
    background-color: #EDECFE;
    color: #2056FF;
    margin-right: 30px;
}

.FicheIntervention .flex-center .flex {
    align-items: center;
    color: #2056FF;
    justify-content: center;
}

.FicheIntervention .flex-center .flex svg {
    font-size: 24px;
}

@media (max-width:1300px) {
    .FicheIntervention {
        flex-direction: column;
        align-items: flex-start;
        min-width: 320px;
        max-width: 100%;
        flex: 1;
    }
    .FicheIntervention .flex-center {
        padding: 20px;
        width: 100%;
    }
    .FicheIntervention .flex-left {
        height: 100px;
        width: 100%;
    }
    .FicheIntervention .flex-center .slider {
        width: 100%;
    }
    .FicheIntervention .add {
        width: 100%;
        max-width: 100%;
    }
}