.Header {
    height: 96px;
    padding-left: 17px;
    padding-right: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: #F3F4F8;
}

@media (min-width:1024px) {
    .app-container:has(.show_logo_only) .Header {
        padding-left: 300px;
    }
}

.menu-button {
    display: none;
}

.Header .header-right {
    display: flex;
    gap: 24px;
    align-items: center;
}

.Header .header-right button {
    font-size: 24px;
    color: #377DFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    min-width: 32px;
    gap: 10px;
}

.Header .header-right button.user {
    min-width: 54px;
}

.Header .header-right button img {
    height: 32px;
}

.Header .header-right button.user svg {
    font-size: 14px;
}

.navigation-bar {
    display: none;
}

@media (max-width:1024px) {
    .menu-button {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
        /* transform: scale(1.2); */
    }
    .menu-button .bar {
        width: 20px;
        border-bottom: solid 2px #21252B;
        height: 2px;
        transition: all 0.4s;
    }
    .menu-button.active .bar {
        transform: rotate(-45deg);
    }
    .menu-button.active .bar:first-child {
        width: 50%;
        transform: translate(2px, 3.2px) rotate(45deg);
    }
    .menu-button.active .bar:last-child {
        width: 50%;
        transform: translate(9px, -4px) rotate(45deg);
    }
}

@media (max-width:560px) {
    .Header {
        height: 76px;
        min-height: 76px;
    }
    .Header .header-right .Notifications,
    .Header .header-right .Email {
        display: none;
    }
    .Header .header-right {
        gap: 0;
    }
    .navigation-bar {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        /* background-color: white; */
        padding: 5px 0;
        justify-content: space-around;
        /* border-top: solid 1px #DBDFED; */
        z-index: 9;
        max-height: 60px;
    }
    .navigation-bar button,
    .navigation-bar a {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #B8BDC5;
        border: solid 2px white;
        position: relative;
        z-index: 5;
    }
    .navigation-bar button {
        font-size: 26px;
    }
    .navigation-bar .key-container {
        height: 42px;
        width: 80px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        transform: translateY(-5px);
        padding-bottom: 7.5px;
        border-radius: 0 0 40px 40px;
        box-shadow: 0px 402px 0px 400px white, 0px 401px 0px 400px #D7D7D7;
        border: solid 1px #D7D7D7;
        border-top: none;
        z-index: 0;
    }
    .navigation-bar a.key,
    .navigation-bar button.key {
        /* display: none; */
        border-radius: 50%;
        color: #377DFF;
        height: 65px;
        background-color: white;
        border: solid 1px #377DFF;
        width: 65px;
        display: flex;
        box-shadow: 0 0px 10px #5555;
        z-index: -1;
    }
    .navigation-bar a.key.active,
    .navigation-bar button.key.active {
        background: linear-gradient(#095efd 50%, #377DFF 90%);
        color: white;
        border: solid 1px white;
    }
    .navigation-bar button.active,
    .navigation-bar a.active {
        color: #377DFF;
        border-bottom: solid 2px #377DFF;
    }
}