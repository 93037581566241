.Popup-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Popup {
    min-height: 250px;
    border-radius: 12px;
    width: 624px;
    background-color: white;
    position: relative;
    padding: 32px 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 90%;
}

.Popup.Delete {
    align-items: center;
    padding: 32px 40px 22px;
}

.Popup-container .overlay {
    position: absolute;
    height: 1000px;
    width: 100%;
    background-color: #0002;
    left: 0;
    top: 0;
    display: flex;
}

.Popup .trash {
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 22px;
    background-color: #FFE0E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Popup .trash svg {
    color: #DC3545;
    font-size: 24px;
}

.Popup h2 {
    display: flex;
    gap: 12px;
    font-family: Outfit;
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    align-items: center;
    color: #282828;
}

.Popup h2 svg {
    color: #377DFF;
}

.Popup.Delete h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
}

.Popup.Delete h4 {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #5F6980;
}

.Popup.Delete p {
    font-family: Outfit;
    font-size: 13.53px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    color: #5F6980;
}

.Popup.Delete .buttons {
    width: 100%;
    display: flex;
    gap: 16px;
}

.Popup.Delete .buttons button {
    flex: 1;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    background-color: white;
    color: #5F6980;
    border: 2px solid #5F6980
}

.Popup.Delete .buttons button:last-child {
    background-color: #DC3545;
    color: white;
    border-color: #DC3545;
}

.Popup p {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.Popup button {
    padding: 12px 28px;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: white;
    background-color: #282828;
    border-radius: 6px;
    margin-left: auto;
    margin-top: auto;
    display: block;
}

@media (max-width:560px) {
    .Popup,
    .Popup.Delete {
        padding: 20px 24px;
        min-height: 200px;
    }
    .Popup h2 {
        font-size: 28px;
    }
    .Popup h2 svg {
        font-size: 30px;
    }
    .Popup button {
        font-size: 16px;
        padding: 10px 28px;
    }
}