.Paiement {
    border-radius: 20px;
    background-color: #F9FAFB;
    padding: 28px 21px;
}

.Paiement .credit-card {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    height: 200px;
    width: fit-content;
    box-shadow: 0px 0px 0.84px 0px #0000000A;
    box-shadow: 0px 1.68px 5.03px 0px #0000000A;
    box-shadow: 0px 13.43px 20.14px 0px #0000000F;
}

.payment-data {
    display: flex;
    gap: 29px;
    flex-wrap: wrap;
}

.payment-data .edit {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    color: white;
}

.Paiement .credit-card img {
    height: 100%;
}

.Paiement .credit-card .info {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 19px;
}

.Paiement .credit-card .info .top {
    height: 30px;
}

.Paiement .credit-card .info h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 22.72px;
    word-spacing: 10px;
}

.Paiement .credit-card p {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.Paiement .credit-card p .date {
    font-size: 14px;
    font-weight: 500;
    line-height: 13.34px;
}

.Paiement .credit-card .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 13.34px;
}

.credit-card .date span {
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 7.59px;
    padding-bottom: 10px;
}

.payment-data form {
    display: block;
    max-width: 600px;
    min-width: max( 50%, 300px);
    flex: 3;
}

.payment-data form h2 {
    font-size: 19px;
    font-weight: 600;
    line-height: 23.94px;
    color: #555555;
    margin-bottom: 25px;
}

.payment-data form button {
    width: 100%;
    max-width: 100%;
    margin-top: 28px;
    height: 44px;
}

.payment-data form a {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    color: var(--primary-color);
    text-decoration: underline;
    font-size: 15.84px;
    font-weight: 300;
    line-height: 35.58px;
}

.settings {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    /* flex: 1; */
    /* justify-content: center; */
}

.settings .switch {
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background-color: #0000000A;
    position: relative;
    transition: all 0.4s;
    margin-bottom: 8px;
}

.settings .switch::after {
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #3758F9;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.4s;
}

.settings .switch input {
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0;
    position: relative;
    z-index: 10;
}

.settings .switch:has(input:checked) {
    background-color: #3758F980;
}

.settings .switch:has(input:checked)::after {
    left: 50%;
}

.settings p {
    font-family: Outfit;
    font-size: 11.75px;
    font-weight: 400;
    line-height: 14.8px;
    text-align: left;
    color: #AEAEAE;
}

.settings p.bold {
    font-family: Outfit;
    font-size: 11.61px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: left;
    color: black;
    margin-top: 0;
}

.settings .slide,
.settings .slide div {
    height: 10px;
    width: 120px;
    background-color: #F1F1F1;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
}

.settings .slide div {
    width: 40%;
    background-color: #3758F9;
    margin: 0;
}

@media (max-width:560px) {
    .Paiement {
        padding: 20px 0;
        box-shadow: none;
        background: none;
        border-radius: 0;
    }
    .app-body:has(.Paiement) {
        background-color: #F9FAFB;
    }
}