.app-container {
  display: flex;
  height: 100dvh;
  /* min-height: 600px; */
  align-items: stretch;
  background-color: #f3f4f8;
}

.app-contents {
  overflow-x: hidden;
  flex: 1;
  height: auto;
}

.app-body {
  padding: 16px 34px;
  min-width: 300px;
}

.list-item {
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
  list-style: none;
}

.list-item button,
.list-item .item {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.38px;
  text-align: left;
  padding: 5px 8px;
  border: solid 1px;
  border: 0.53px solid #dbdfed;
  border-radius: 5px;
  margin-top: 7px;
  width: 160px;
}

.list-item .item svg {
  margin-left: auto;
  font-size: 14px;
  color: #dc3545;
  cursor: pointer;
  padding: 2px;
}

@media screen and (forced-colors: none) {
  ::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }
}

@media screen and (forced-colors: none) {
  ::-webkit-scrollbar-thumb {
    border: none;
    box-shadow: none;
    background: rgb(218, 220, 224);
    border-radius: 4px;
    min-height: 40px;
  }
}

@media screen and (forced-colors: none) {
  ::-webkit-scrollbar-track {
    margin: 3px 0;
  }
}

button:active {
  opacity: 0.5;
}

.avatar {
  display: flex;
  align-items: center;
  gap: 5px;
  align-items: stretch;
  flex: 1;
  min-width: 200px !important;
  overflow: hidden;
}

.avatar img:not(.pdf) {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.avatar h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 15.25px;
  text-align: left;
  white-space: nowrap;
}

.avatar h6 {
  font-size: 12px !important;
  font-weight: 400;
  text-align: left;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
  /* justify-content: space-evenly; */
  /* margin-top: 11px; */
}

.input-container,
form .flex {
  max-width: 440px;
  flex: 1;
  display: flex;
  min-width: max(calc(50% - 21px), 200px);
}

.input-container {
  flex-direction: column;
  justify-content: flex-end;
}

form .flex.full {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.input-container.full {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.flex.full .input-container {
  min-width: 200px;
  max-width: 100%;
}

.input-container label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-container input,
.input-container select,
.input-container textarea,
.input-container .check-input {
  width: 100%;
  height: 46px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #e4e7eb;
  background-color: white;
  padding: 10px 13px;
  margin-top: 8px;
  box-shadow: none;
}

.input-container input.error,
.input-container select.error,
.input-container textarea.error,
.input-container .check-input.error {
  border: solid 1px #dc3545 !important;
}

.input-container span.error {
  color: #dc3545 !important;
  font-size: 10px;
  display: flex;
  min-width: 100%;
  height: 20px;
  overflow: hidden;
}

.input-container textarea {
  min-height: 80px;
}

form .flex {
  gap: 20px;
  flex-wrap: wrap;
}

.bold {
  font-weight: bold !important;
}

.checkboxs {
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  gap: 10px;
}

.check-box {
  display: flex;
  gap: 10px;
  position: relative;
  overflow: hidden;
  align-items: center;
  min-width: 120px;
}

.check-box .out {
  border: solid 1px #dfe4ea;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.check-box input:checked ~ .out {
  border: none;
  background-color: #3758f9;
}

.check-box input:checked ~ .out svg {
  /* display: none; */
}

.check-box input {
  min-width: 100px;
  height: 100px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.check-box svg {
  font-size: 20px;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.users {
  display: flex;
  justify-content: center;
  margin-top: 11px;
}

.users .user {
  width: 30px;
  display: flex;
  overflow: visible;
}

.users .user img {
  min-width: 46px;
  height: 46px;
  border-radius: 50%;
}

.users .user .items {
  min-width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #8ab1fc;
  display: flex;
  justify-content: center;
  align-items: center;
   
  font-size: 19.88px;
  font-weight: 400;
  line-height: 22.94px;
  text-align: right;
  color: #ffffff;
}

.loading svg {
  transform-origin: center;
  animation: loading_anime 1s infinite linear;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../public/images/down.svg') !important;
  background-repeat: no-repeat, repeat !important;
  background-position: right 0.7em top 50%, 0 0 !important;
  background-size: 0.8em auto, 100% !important;
  border: none;
  border-radius: 0px;
  padding: 0em 0em 0em 0.35em;
  padding-right: 30px !important;
}

.inputs-label {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.inputs-label .input-container {
  position: relative;
  width: 100%;

  border-radius: 10px;
  box-shadow: 0px 5.11px 20.18px 0px #00000008;
  border: solid 1px #dadada;
  padding: 0;
}

.inputs-label .input-container label {
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
}

.inputs-label input,
.inputs-label select {
  border: none;
  height: 45px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  padding-left: 20px;
  margin: 0;
}

@keyframes loading_anime {
  100% {
    transform: rotate(-360deg);
  }
}

@media (max-width: 560px) {
  .app-body {
    padding: 34px 20px;
    padding-bottom: 100px !important;
  }
  .input-container,
  form .flex {
    min-width: 100%;
  }
}
