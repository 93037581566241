.Table .filter {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 10px;
}

.Table .filter .Search,
.Table .filter button {
    height: 44px;
    width: 220px;
    border: 1px solid #DBDFED;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Table .filter button {
    color: #B2B2B2;
    width: 60px;
    font-size: 13.16px;
    font-weight: 400;
    line-height: 16.58px;
}

.Table .filter button svg {
    font-size: 20px;
}

.Table .filter button.filter-button {
    width: 125px;
    gap: 4px;
}

.Table .table-container {
    display: flex;
    max-width: 100%;
    overflow: auto;
    padding-bottom: 5px;
}

.Table table {
    width: 100%;
    margin-top: 22px;
    text-align: left;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.Table th {
    font-size: 17.23px;
    font-weight: 600;
    line-height: 26.8px;
    color: #495057;
    background-color: #FFFFFF;
}

.Table td,
.Table th {
    min-height: 60px;
    padding: 20px;
    white-space: nowrap;
    background-color: #FFFFFF;
    text-align: center;
}

.Table tr *:first-child {
    text-align: left;
}

.Table td {
    font-size: 15px;
    justify-content: center;
}

.Table td .flex {
    align-items: center;
    gap: 5px;
}

.Table tr:nth-child(2n+1) td {
    background-color: #F9FAFB;
}

.Table .pagination {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 20px;
}

.Table .pagination button {
    height: 34px;
    width: 34px;
    background-color: #F8F9FA;
    border: 1px solid #DFE4EA;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #637381;
}

.Table .pagination button:active,
.Table .pagination button.active {
    color: #F8F9FA;
    background-color: #3758F9;
    border-color: transparent;
}

.Table .pagination button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.Table .avatar {
    gap: 8px;
}

.Table .avatar img {
    height: 50px;
    width: 50px;
}

.Table .avatar div {
    gap: 10px;
}

.Table span.success,
.Table span.dark {
    display: flex;
    padding: 8px 0;
    width: 80px;
    border-radius: 20px;
    background-color: #DEF7EC;
    justify-content: center;
    color: #495057;
    margin: auto;
}

.Table span.dark {
    background-color: #E5E5E5;
}

.Table td>* {
    margin: auto;
}

.Table td button {
    padding: 7px 15px;
    background-color: #E5E5E5;
    border-radius: 5px;
}

.Table .color-danger {
    color: #FF481FCC;
    background: transparent;
}

.Table .color-primary {
    color: #1570EF;
    padding: 10px;
}