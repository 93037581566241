.upload {
  position: relative;
  cursor: pointer;
}

.upload.hiden.upload-button {
  opacity: 0;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: red;
}

.upload input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload .edit {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50% !important;
  right: 4px;
  bottom: 4px;
  background-color: white;
  padding: 0px !important;
  border: solid 1px #377dff !important;
  display: flex;
  pointer-events: none;
}

.upload .edit svg {
  font-size: 16px;
  color: #377dff;
  margin: auto;
}
