.Tracking {
  min-height: 100dvh;
  display: flex;
  align-items: stretch;
}

.Tracking .flex {
  justify-content: space-between;
}

.Tracking-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  gap: 40px;
  background-color: #377dff;
  padding: 35px;
  min-height: 100dvh;
  position: sticky;
  top: 0;
  max-width: calc(100% - 400px);
  flex: 1;
}

.Tracking-Technicien .Tracking-container {
  gap: 0px;
}

.Tracking-container .PdfReader {
  /* height: 100%; */
  background: white;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  position: relative;
  height: fit-content;
  border-radius: 12px;
}

.Tracking-container .PdfReader .bottom {
  display: flex;
  max-width: 100%;
  position: relative;
  z-index: 10;
  width: 600px;
  margin-top: 60px;
  gap: 24px;
}

.Tracking-container .PdfReader .bottom button {
  flex: 1;
  max-width: inherit;
  width: auto;
  /* height: 60px; */
}

.Tracking-container .PdfReader .bottom button.white {
  background-color: transparent;
  color: #377dff;
  border: #377dff solid 1px;
}

.Tracking-container .PdfReader .PdfView {
  border: solid 1px #eee;
  box-shadow: -8px 11px 9.9px 0px #00000014;
}

.Tracking-container .PdfReader .PdfView .top {
  display: none;
}

.Tracking .loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  font-size: 38.44px;
  color: white;
  font-weight: 700;
}

.Tracking .loading svg {
  height: 79px;
  width: 79px;
  font-weight: 400;
}

.Tracking h3 {
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.Tracking-container > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.Tracking p {
  color: white;
}

.Tracking .steps {
  width: 100%;
  min-height: 260px;
  border-radius: 12px;
  background-color: white;
  margin-top: 20px;
  padding: 21px 28px;
  color: #0a0d13;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Tracking .steps p {
  color: #0a0d13;
  font-size: 16px;
}

.Tracking .steps > p {
  position: sticky;
  left: 0;
}

.Tracking .steps-container {
  position: relative;
}

.Tracking .steps-container .line {
  min-width: 140px;
  width: 100%;
  height: 5px;
  top: 18.5px;
  background-color: #e0e0e0;
  position: absolute;
  z-index: -1;
  right: 50%;
  transform: translate(21px);
}

.Tracking .steps .step svg {
  height: 42px;
  width: 42px;
  background-color: white;
  position: relative;
  z-index: 10;
}

.Tracking .steps .step {
  display: flex;
  flex-direction: column;
  color: #e0e0e0;
  align-items: center;
  gap: 20px;
  width: 20%;
  min-width: 140px;
  position: relative;
  padding: 0 10px;
  z-index: 5;
  text-align: center;
  transition: all 1s;
  position: relative;
}

.Tracking .steps .step.active {
  color: #377dff;
}

.Tracking .line div {
  height: 100%;
  width: 0;
  background: #377dff;
}

.Tracking .step.active .line div {
  width: 100%;
}

.Tracking .success {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  margin-top: 60px;
}

.Tracking .success img {
  position: absolute;
  height: 200px;
  width: 200px;
  min-width: 200px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.Tracking .success svg {
  font-size: 70px;
}

.Tracking .success h1 {
  font-size: 52.08px;
  font-weight: 700;
  line-height: 54.06px;
  text-align: left;
}

.Tracking .Technicien {
  border-radius: 12px;
  background-color: white;
  margin-bottom: auto;
  flex: 1;
  padding: 14px 26px;
}

.Tracking .Technicien h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: left;
  margin-bottom: 13px;
}

.Tracking .Technicien .flex {
  flex-wrap: wrap;
  overflow: auto;
  gap: 20px;
  justify-content: flex-start;
  padding-bottom: 11px;
}

.Tracking .Technicien .table {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  white-space: nowrap;
  text-align: left;
  flex: 1;
  max-width: 500px;
  min-width: min(100%, 300px);
}

.Tracking .Technicien table td,
.Tracking .Technicien table th {
  padding: 10px;
  font-size: 8.7px;
  font-weight: 700;
  line-height: 13.05px;
  text-align: left;
  color: #6b7280;
}

.Tracking .Technicien table td {
  font-weight: 400;
  text-align: center;
}

.Tracking.Tracking-Technicien .overlay {
  display: none;
}

.Tracking .Technicien .Description {
  background-color: #f3f4f8;
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 18px;
}

.Tracking .Technicien .Description h2 {
  text-align: center;
  font-weight: 700;
}

.Tracking .Technicien .Description p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #9ca3af;
  background-color: white;
  border-radius: 6px;
  padding: 7px 10px;
  border: solid 1px #dfe4ea;
}

.Tracking .Technicien form {
  gap: 40px;
  justify-content: center;
}

.Tracking .Technicien form img {
  flex: 1;
  width: 350px;
  object-fit: contain;
  max-width: 400px;
  margin: auto;
}

.Tracking .Technicien .check-box {
  box-shadow: 5.59px 5.59px 16.78px 0px #0202460d;
  padding: 16px 27px;
  height: auto;
  border-radius: 20px;
  border-color: #f7f7f7;
}

.Tracking .Technicien .check-box p {
  color: #344054;
  font-size: 9.79px;
  font-weight: 500;
  text-align: left;
}

.Tracking .Technicien .check-box p span {
  display: flex;
  font-size: 9.79px;
  font-weight: 400;
  line-height: 13.98px;
  text-align: left;
  color: #667085;
}

.Tracking .Technicien .success {
  color: #377dff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1; */
  min-width: 200px;
}

.Tracking .Technicien .success p {
  color: #0a0d13;
  margin-top: 40px;
  font-size: 12.56px;
  font-weight: 500;
  line-height: 18.85px;
  text-align: left;
}

.Tracking .Technicien .success span {
  font-size: 9.89px;
  font-weight: 300;
  line-height: 14.83px;
  text-align: left;
  color: black;
}

.Tracking .Technicien .success .flex {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.Tracking .Technicien .line {
  background: #b3b3b3;
  min-height: 0.5px;
  min-width: 0.5px;
}

@media (max-width: 1200px) {
  .Tracking .Technicien .line {
    display: none;
  }
}

@media (max-width: 920px) {
  .Tracking {
    flex-direction: column;
  }
  .Tracking .Tracking-container {
    max-width: 100%;
    position: relative;
    padding: 20px;
  }
}

@media (max-width: 560px) {
  .Tracking.Tracking-Technicien .Tracking-container {
    padding: 10px;
  }
  .Tracking .loading {
    font-size: 28px;
  }
  .Tracking {
    flex-direction: column;
  }
  .Tracking .loading svg {
    height: 60px;
  }
  .Tracking h3 {
    font-size: 25px;
  }
  .Tracking .steps p {
    font-size: 11px;
  }

  .Tracking .Technicien {
    padding: 14px;
  }
  .Tracking .Technicien form {
    flex-direction: column;
  }
  .Tracking .Technicien .line {
    display: block;
  }
  .Tracking .Technicien .flex {
    overflow: hidden;
  }
  .Tracking .Technicien table {
    display: flex;
    white-space: inherit;
    max-width: 100%;
  }
  .Tracking .Technicien table tr {
    display: flex;
    flex-direction: column;
  }
  .Tracking .Technicien table td {
    white-space: break-spaces;
    text-align: left;
  }
  .Tracking-container .PdfReader .bottom {
    margin-top: 30px;
  }
}
