.CallData .inputs-label{
    display: flex;
    flex-direction: row;

}

.CallData .input-container{
    width: auto;
    min-width: 200px
}

.CallData .inputs-label label{
position: relative;
height: 1px;
width: fit-content;
display: flex;
align-items: center;
}

.CallData .inputs-label input{
    max-width: 100%;
    display: block;
    flex: 1;
}


.CallData .input-container::-webkit-calendar-picker-indicator{
    color: red;
    display: none;
}
.CallData .table{
    max-width: 100%;
    margin-top: 30px;
    overflow-x: auto;
    /* overflow: hidden; */
    display: flex;
    padding-bottom: 20px;


}
.CallData table{
    min-width: 1000px;
    width: 100%;
}

.CallData  tr{
   background-color: white;
    border: solid 1px #E1E1E1;
    border-radius: 10px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    padding: 0 15px;
  

}

.CallData  tr td,
.CallData  tr th{
    flex: 1;
    text-align: left;
}


.CallData tbody tr{
    height: 80px;
}


.CallData th{
    border-radius: 10px;
}

.CallData .avatar{
    display: flex;
}

.CallData .avatar img{
    height: 48px;
    width: 48px;
    border-radius: 50%;
}

.CallData .avatar span{
    font-size: 12px;
    font-weight: 600;
}
.CallData .avatar p{
    font-size: 8px;
}

.CallData td span.time{
    color: #377dff;
    background-color: #377dff20;
    min-width: 40px;
    width: fit-content;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 40px;
    padding: 0 20px;
}

@media (max-width:720px) {
    .CallData .inputs-label{
        flex-direction: column;
    } 
}
