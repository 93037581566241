.SinglePaiemen {
    gap: 0px;
    border-radius: 37px;
    background-color: #FFFFFF;
    margin-top: 33px;
}

.SinglePaiemen .container {
    padding: 16px;
    border-bottom: solid 1px #CDCDCD;
}

.SinglePaiemen .container .line {
    width: 100%;
    margin: 15px 0;
    display: flex;
    border-bottom: solid 1px #CDCDCD;
}

.SinglePaiemen .avatar {
    align-items: center;
}

.SinglePaiemen .avatar img {
    height: 50px;
    width: 50px;
}

.SinglePaiemen .avatar h5 {
    font-size: 21px;
}

.SinglePaiemen .avatar h5 {
    font-size: 18px;
}

.SinglePaiemen .avatar p {
    margin-left: auto;
}

.SinglePaiemen .flex {
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
}

.SinglePaiemen .flex h6 {
    font-weight: 400;
    color: #474747;
}

.SinglePaiemen .flex p {
    max-width: 300px !important;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.SinglePaiemen p.flex {
    align-items: center;
    gap: 10px;
}

.SinglePaiemen .flex .container {
    max-width: fit-content;
}

.SinglePaiemen .container h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 33.96px;
    text-align: left;
}

.SinglePaiemen .container span {
    color: #377DFF;
    display: flex;
}

.SinglePaiemen .container .info {
    display: block;
    min-width: 300px;
    max-width: 300px;
    color: #474747;
}

.SinglePaiemen .container span.info a {
    color: #377DFF;
}

.SinglePaiemen .container:last-child {
    border-bottom: none;
}

.SinglePaiemen .container:last-child .flex {
    gap: 0;
}

.app-logo {
    display: none;
}

@media (max-width:560px) {
    .SinglePaiemen {
        border-radius: 20px;
    }
}

@media print {
    .SinglePaiemen {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        background-color: red !important;
        margin: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .SinglePaiemen .container:nth-child(3),
    .SinglePaiemen .container:nth-child(4) {
        display: none;
    }
    .app-logo {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}