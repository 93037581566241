.HomeCard {
    background-color: white;
    border-radius: 10px;
    min-height: 350px;
    /* min-width: 320px; */
    max-width: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 0.92px solid #F0EFFB;
    padding-bottom: 10px;
    cursor: pointer;
    height: 100%;
    min-height: 100%;
}

.card-2 {
    padding: 20px;
    background-color: white;
    border-radius: 25px;
    border: 0.92px solid #F0EFFB;
    /* padding-bottom: 0px; */
    min-width: 320px;
    max-width: 600px;
    flex: 1;
    flex-direction: column;
    height: 100%;
}

.card-2 .HomeCard {
    border: none;
    border-radius: 0;
    min-width: 100% !important;
    max-width: 100% !important;
    height: fit-content;
    padding-bottom: 0px;
}

.card-2 .HomeCard .card-body {
    padding-left: 0;
    padding-right: 0;
}

.card-2 .HomeCard .flex button {
    padding: 6px 20px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    border: none;
    height: auto;
    width: auto;
    border-radius: 5px;
    background-color: #377DFF;
}

.HomeCard img {
    width: 100%;
    height: 220px;
    /* min-height: 165px; */
    object-fit: cover;
    /* flex: 1; */
}

.pop {
    height: 1px;
    display: flex;
    align-items: center;
}

.pop span {
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 6.6px 6.6px 6.6px 0px;
    gap: 4px;
    color: white;
    background-color: #377DFF;
    align-items: center;
    font-size: 14px;
    justify-content: center;
}

.card-body {
    padding: 13px 20px 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.card-body .flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.card-body .price {
    font-size: 19.8px;
    font-weight: 800;
    line-height: 29.7px;
    letter-spacing: -0.8250000476837158px;
    text-align: left;
    color: #377DFF;
}

.card-body .price span:first-child {
    font-size: 12.38px;
    line-height: 18.56px;
    letter-spacing: -0.8250000476837158px;
    margin-left: 4px;
}

.card-body .price span:last-child {
    font-size: 13.2px;
    font-weight: 500;
    line-height: 19.8px;
    text-align: left;
    color: #00092980;
    margin-left: 3px;
}

.card-body .flex button {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    font-size: 26px;
    color: #377DFF;
    border: 1.24px solid #E8E6F9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-body h4 {
    font-size: 19.8px;
    font-weight: 700;
    line-height: 29.7px;
    letter-spacing: -0.01em;
    text-align: left;
}

.card-2 .card-body h4 {
    font-size: 15px;
    color: #3B3A5D;
    margin-top: 14px;
}

.card-2 .HomeCard .diponible svg {
    color: #377DFF;
    font-size: 14px;
}

.card-2 .HomeCard .line {
    border: none;
}

.card-body p {
    font-size: 13.2px;
    font-weight: 500;
    /* line-height: 19.8px; */
    text-align: left;
    color: #00092980;
    margin-bottom: 10px;
}

.card-body p.diponible {
    margin-bottom: 0px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.card-body p.diponible.active span {
    background-color: #12C023;
}

.card-body p.diponible span {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #787878;
}

.card-body .line {
    border: 1.24px solid #F0EFFB;
    margin-bottom: 13px;
    margin-top: auto;
}

.card-body .flex .item {
    display: flex;
    align-items: center;
    gap: 7px;
    color: #000929A0;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.17px;
    text-align: left;
    min-width: 140px;
    max-width: 100%;
    flex: 1;
}

.card-body .flex .flex,
.card-body .flex.flex-last .flex .item {
    flex: 1;
}

.card-body .flex.flex-last {
    gap: 4px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: auto;
}

.card-body .flex .item svg {
    color: #377DFF;
    font-size: 20px;
}

.card-2 .flex .flex {
    gap: 4px;
    flex-wrap: wrap;
}

.card-2 .flex.flex-last .item {
    color: #3B3A5D;
    gap: 2px;
    border: 0.44px solid #D7D7D7;
    padding: 5px 10px;
    border-radius: 4px;
}

.card-2 .flex.flex-last .item svg {
    color: #3B3A5D;
    font-size: 14px;
}

.card-2 .flex.flex-last .item span {
    border-right: solid 1px #D7D7D7;
    height: 10px;
}

@media (max-width:560px) {
    .HomeCard {
        max-width: 100% !important;
        min-width: 100%;
    }
    .card-2 {
        min-width: 100%;
        display: block;
    }
    .card-2 .flex.flex-last {
        flex-wrap: wrap;
        gap: 10px;
    }
    .card-2 .flex.flex-last .item {
        color: #3B3A5D;
        flex: 1;
        padding-left: 20px;
        gap: 10px;
        min-width: 160px;
    }
    .card-2 .flex.flex-last .flex {
        flex-wrap: wrap;
        flex: 1;
    }
    .card-2 .flex.flex-last .item svg {
        color: #3B3A5D;
    }
}