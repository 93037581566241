.Dashboard {
}

.Dashboard h2 {
  font-size: 33.57px;
  font-weight: 400;
  text-align: left;
  color: #212121;
}

.Dashboard h2 span {
  text-transform: capitalize;
}

.Dashboard h6 {
  font-size: 15.32px;
  font-weight: 400;
  line-height: 20.45px;
  text-align: left;
  color: #bfbfbf;
}

.grid-dash {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.grid-dash .card-2 {
  max-width: 400px;
}

.grid-dash .card-2 img {
  flex: 1;
}

.grid-dash > a {
  /* flex: 1; */
  min-width: min(400px, 100%);
}

.Dashboard .card-data {
  flex: 1;
  min-height: 237.35px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Dashboard .card-data > div {
  background-color: white;
  padding: 11.43px 25px 11.43px 25px;
  border-radius: 10px;
  flex: 1;
}

.Dashboard .card-data h3 {
  font-size: 19px;
  font-weight: 600;
  line-height: 28.5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Dashboard .card-data h3 img {
  height: 32px;
}

.Dashboard .card-data .card-data-body {
  margin-top: 13px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.Dashboard .card-data .item {
  flex: 1;
  min-width: max(40%, 250px);
  overflow: hidden;
}

.Dashboard .card-data .pdf {
  margin-left: auto;
  height: 30px;
}

.Dashboard .card-data button {
  width: 80px;
  height: 32px;
  border-radius: 5px;
  border: 0.5px solid #dbdfed;
  font-size: 12px;
  font-weight: 400;
  line-height: 10.31px;
}

.Dashboard .card-data button:active {
  opacity: 0.5;
}

.Dashboard .card-data .item .details {
  width: 100%;
  padding: 8px 12px;
  margin-top: 10px;
  background-color: #f3f4f8;
  border-radius: 11.95px;
  position: relative;
}

.Dashboard .card-data .item .details .price {
  font-size: 14px;
  font-weight: 600;
  padding-left: 30px;
}

.Dashboard .card-data .item .details .position {
  font-size: 10px;
  font-weight: 400;
  line-height: 11px;
  text-align: left;
  max-width: 200px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #6b7280;
}

.Dashboard .card-data .item .details .position svg {
  min-width: 25px;
  height: 25px;
}

.Dashboard .card-data .item .details .value {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
  line-height: 11.43px;
  text-align: left;
}

.Dashboard .card-data .details .stars {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #5d5dff;
  align-items: center;
  margin-left: auto;
}

.Dashboard .details.danger {
  background: #fa04048a !important;
  color: white;
  min-height: 40px;
  padding: 10px !important;
}

.Dashboard .details.danger .avatar {
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
.Dashboard .card-data .item .details.danger {
  overflow: hidden;
  max-width: 100%;
}
.Dashboard .card-data .item .details.danger .avatar {
  min-width: fit-content;
  white-space: nowrap;
}
.Dashboard .details.danger h5 {
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Dashboard .details.danger p {
  margin-left: 20px;
  color: white !important;
  font-weight: 300 !important;
}

@media (max-width: 1100px) {
  .Dashboard .card-data .card-data-body .item:nth-child(4) {
    display: none;
  }
}

@media (max-width: 560px) {
  .grid-dash {
    flex-direction: column;
  }
  .Dashboard h2 {
    font-size: 25px;
  }
  .Dashboard h6 {
    font-size: 15px;
  }
  .Dashboard .card-data {
    min-width: 100%;
  }
  .Dashboard .details.danger .avatar {
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    max-width: 250px;
  }
}
