.CaptureService {
    background-color: #F9FAFB;
    border-radius: 20px;
    padding: 15px;
}

.CaptureService .hero {
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.CaptureService video {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.CaptureService .hero .info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 13px 48px;
    background-color: #0005;
    color: white;
}

.CaptureService .hero .info p {
    font-size: 20px;
    font-weight: 300;
}

.CaptureService .info h1 {
    font-size: 43.22px;
    font-weight: 900;
    line-height: 46.74px;
    text-align: left;
    color: white;
    width: 400px;
    max-width: 100%;
}

.CaptureService .info .flex {
    justify-content: space-between;
    margin-top: 38px;
    align-items: flex-end;
    gap: 40px;
}

.CaptureService .info button {
    font-size: 14px;
    font-weight: 400;
}

.CaptureService .info .btn-out {
    border: solid 1px #FFF;
    width: 213px;
    height: 50px;
    border-radius: 10px;
    font-size: 17.04px;
    font-weight: 400;
    line-height: 21.47px;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CaptureService>.flex {
    flex-wrap: wrap;
    margin-top: 25px;
    gap: 40px;
}

.Numerisez {
    width: 500px;
    flex: 1;
    max-width: 100%;
}

.Numerisez .flex {
    align-items: center;
    padding: 26px 0px;
    gap: 20px;
}

.Numerisez h2 {
    font-size: 67.88px;
    font-weight: 500;
    line-height: 63.81px;
    text-align: left;
}

.Numerisez svg {
    font-size: 80px;
}

.Numerisez p,
.Numerisez li {
    font-size: 16.15px;
    font-weight: 400;
    line-height: 22.62px;
    text-align: justify;
    color: #2F2F2F;
}

.Numerisez ul {
    margin-top: 20px;
}

.Numerisez li {
    list-style: outside;
    margin-left: 20px;
}

.Processus {
    background-color: #F3F4F8;
    flex: 1;
    padding: 21px 50px;
    max-width: 100%;
}

.Processus h4 {
    font-size: 35px;
    font-weight: 500;
    line-height: 32.9px;
    margin-bottom: 20px;
}

.Processus .etap {
    box-shadow: 6.95px 6.95px 20.84px 0px #0202460D;
    border-radius: 30px;
    background-color: white;
    height: 100px;
    width: 400px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 11px;
}

.Processus .etap::before {
    content: 'étape';
    text-transform: capitalize;
    box-shadow: 9.93px 0px 10.06px 0px #02024629;
    width: 100px;
    transform: rotate(-90deg) translateY(-30px) translateX(-30px);
    justify-content: center;
    height: 40px;
    transform-origin: center;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 5;
}

.Processus .number {
    height: 80px;
    width: 80px;
    min-width: 80px;
    background: #377DFF;
    color: #FFF;
    font-size: 50px;
    font-weight: 900;
    border-radius: 0 40px 40px 0;
    display: flex;
    align-items: center;
    padding-left: 25px;
}

.Processus .etap p {
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    color: #667085;
}

.Processus .etap p.bold {
    color: #020246;
}

@media (max-width:560px) {
    .app-body:has(.CaptureService) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
    }
    .CaptureService .hero .info {
        padding: 13px 20px;
    }
    .CaptureService .hero .info .flex {
        flex-direction: column;
        align-items: center;
    }
    .CaptureService .info .btn-out {
        width: 100%;
    }
    .Numerisez h2 {
        font-size: 45px;
        line-height: 50px;
    }
    .Processus {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        padding-bottom: 0;
    }
    .Processus .etap {
        border: solid 1px #66708525;
    }
}