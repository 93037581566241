.Notifications {
  position: relative;
}
.Notifications > button svg {
  position: relative;
}
.Notifications.active > button::after {
  content: '';
  left: 50%;
  bottom: 50%;
  height: 10px;
  width: 10px;
  max-width: 10px;
  border-radius: 50%;
  background-color: #e03137;
  border: solid 1px #fff;
  position: absolute;
  display: flex;
}
.Notifications .list {
  position: absolute;
  right: 0;
  top: 100%;
  width: 340px;
  background-color: white;
  box-shadow: 0px 4px 6px -2px #0000000d;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  z-index: 10;
  max-height: 500px;
  overflow: auto;
}

.Notifications .list .item {
  padding: 12px;
  font-size: 11.12px;
  font-weight: 500;
  line-height: 15.88px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
}

.Notifications .overlay {
  display: block !important;
  background-color: #33415510;
  left: 0;
  top: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.Notifications .list .item svg {
  font-size: 10px;
  min-width: 10px;
  color: #64748b;
}

.Notifications .list .item > button {
  height: 20px;
  width: 20px;
}

.Notifications .list .item p {
  margin: 5px 0;
  font-size: 11.12px;
  font-weight: 400;
  line-height: 15.88px;
  text-align: left;
  color: #64748b;
}

.Notifications .list .item .flex {
  min-height: 20px;
  gap: 5px;
}

.Notifications .list .item .flex button {
  height: 30px;
  color: white;
  font-size: 12px;
  border-radius: 6px;
  min-width: 100px;
}

.Notifications .list .item .flex button.white {
  color: #377dff;
  background-color: transparent;
}

.Notifications .list .item .flex button svg {
  color: currentColor;
  font-size: 12px;
}

@media (max-width: 560px) {
  .Notifications .list {
    position: fixed;
    top: inherit;
    bottom: 50%;
    width: 300px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
